import './App.css';
import { useContext, useState } from 'react'
import Board from './components/Board'
import TitleScreen from './pages/TitleScreen'
import GameScreen from './pages/GameScreen'
import SettingsScreen from './pages/SettingsScreen'
import { Grid } from '@mui/material'
import DifficultyContext from './context/DifficultyContext';
import BoardThemeContext from './context/BoardThemeContext';
import { MediumDonuts, IDonutChunk } from './consts';
import DonutChunksContext from './context/DonutChunksContext';

export default function App() {
  const [screen, setScreen] = useState<string>('title')
  const [boardDifficulty, setBoardDifficulty] = useState<number>(1)
  const [boardTheme, setBoardTheme] = useState<number>(0)
  const [donutChunks, setDonutChunks] = useState<IDonutChunk[]>(MediumDonuts)
  
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    setScreen(e.currentTarget.id)
  }

  const screens: Record<string, React.ReactNode> = {
    title: <TitleScreen clickHandler={handleClick} />,
    game: <GameScreen clickHandler={handleClick} />,
    settings: <SettingsScreen clickHandler={handleClick} />,
  }

  return (

    <BoardThemeContext.Provider value={{ boardTheme, setBoardTheme }}>
      <DifficultyContext.Provider value={{ boardDifficulty, setBoardDifficulty }}>
        <DonutChunksContext.Provider value={{ donutChunks, setDonutChunks }}>
        <Grid alignItems="center" style={{ height: '100%', width: '100%' }} justifyContent='center'>
          <Grid item xs={12}>
            {
              screens[screen]
            }
          </Grid>
        </Grid>
        </DonutChunksContext.Provider>
      </DifficultyContext.Provider>
    </BoardThemeContext.Provider>

  );
}
