import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch } from "@mui/material"
import { motion } from 'framer-motion'
import QuarterDonut from '../components/DonutChunk'
import { EasyDonuts, MediumDonuts, HardDonuts, innerRadius, outerRadius, IDonutChunk } from '../consts'
import { useContext, useEffect, useState } from "react"
import Button from "../components/Button"
import DifficultyContext from "../context/DifficultyContext"
import BoardThemeContext from "../context/BoardThemeContext"
import DonutChunksContext from "../context/DonutChunksContext"

const SettingsScreen = ({ clickHandler }: { clickHandler: React.MouseEventHandler<HTMLDivElement> }) => {
	const [animationPhase, setAnimationPhase] = useState<string>('enter')
	const { donutChunks, setDonutChunks } = useContext(DonutChunksContext)
	const { boardDifficulty, setBoardDifficulty } = useContext(DifficultyContext)
	const { boardTheme, setBoardTheme } = useContext(BoardThemeContext)

	const variants = {
		initial: { opacity: 1 },
		exit: {
			y: [50, -220], // Starts at 0, moves up to -20, then down to 220
			opacity: 0,
			transition: {
				y: {
					type: 'spring',
					stiffness: 300,
					damping: 20,
					duration: 0.25
				},
				opacity: {
					duration: 0.2 // Adjust as needed
				}
			}
		}
	};


	useEffect(() => {
		console.log(boardTheme)
	}, [boardTheme])

	useEffect(() => {
		switch(boardDifficulty) {
			case 0:
				setDonutChunks(EasyDonuts)
				break
			case 1:
				setDonutChunks(MediumDonuts)
				break
			case 2:
				setDonutChunks(HardDonuts)
				break
			default:
				setDonutChunks(MediumDonuts)
		}
	}, [boardDifficulty])


	useEffect(() => {
  console.log('Board theme changed:', boardTheme);
}, [boardTheme]);


	return (
		<Grid container alignContent='center' alignItems='center' >
			<Grid item xs={4} md={4.5} lg={4.75}></Grid>
			<Grid item xs={4} md={3} lg={2.5} display='flex' alignContent='center' alignItems='center' justifyContent='center'>
				<Grid container direction='row'>
					<Grid item xs={12}>
							<FormLabel id="demo-radio-buttons-group-label"><h1 style={{ fontSize: '2em', color: 'white' }}>Settings</h1></FormLabel>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								value={boardDifficulty}
								name="radio-buttons-group"
								style={{ color: 'white' }}
								onChange={(e) => { setBoardDifficulty(parseInt(e.target.value))}}
							>
								<FormControlLabel value="0" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="Easy" />
								<FormControlLabel value="1" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="Medium" />
								<FormControlLabel value="2" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="Hard" />
							</RadioGroup>
							<FormControlLabel sx={{ paddingTop: '25px', paddingBottom: '25px' }} control={<Switch defaultChecked />} label="Sound On" />
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								value={boardTheme}
								name="radio-buttons-group"
								style={{ color: 'white' }}
								onChange={(e) => { setBoardTheme(parseInt(e.target.value))}}
							>
								<FormControlLabel value="0" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="Classic" />
								<FormControlLabel value="1" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="Pastel" />
								<FormControlLabel value="2" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="Grayscale" />
							</RadioGroup>
					</Grid>
					<Grid item>
						<Grid container direction='row' style={{ paddingTop: '25px' }}>
							{
								donutChunks.map((donut: IDonutChunk) => {
									return (
										<Grid item xs={6}  key={`donut-${donut.uid}-${boardTheme}-${boardDifficulty}`}>
											<QuarterDonut
												id={donut.id}
												className={`quarterdonut-${boardTheme}-${boardDifficulty}`}
												innerRadius={innerRadius}
												outerRadius={outerRadius}
												startAngle={donut.startAngle}
												endAngle={donut.endAngle}
												color={donut.colors[boardTheme]}
												x={donut.x}
												y={donut.y}
												dimensions={donut.dimensions}
												audio={donut.audio}
											/>
										</Grid >
									)
								})
							}
							<Grid item xs={12} textAlign='center' paddingBottom='45px'>
								<motion.div variants={variants} initial="initial" animate={animationPhase} exit="exit">
									<Button type='title' clickHandler={clickHandler}>
										<h1 style={{ fontSize: '2em', zIndex: 1000 }}>SIMON</h1>
									</Button>
								</motion.div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>

					</Grid>
				</Grid >
			</Grid>
			<Grid item xs={2}></Grid>
		</Grid>
	)
}

export default SettingsScreen