import QuarterDonut from '../components/DonutChunk'
import { useState, useEffect, MouseEventHandler, useContext } from 'react'
import { innerRadius, outerRadius, HardDonuts, EasyDonuts, MediumDonuts } from '../consts'
import { Grid } from '@mui/material'
import Button from '../components/Button'
import { motion } from 'framer-motion'
import DifficultyContext from '../context/DifficultyContext'
import BoardThemeContext from '../context/BoardThemeContext'
import DonutChunksContext from '../context/DonutChunksContext'
import win from '../audio/win.wav'
import lose from '../audio/lose.wav'

enum IStatus {
	SUCCESS,
	FAIL,
	NUETRAL
}

const GameScreen = ({ clickHandler }: { clickHandler: MouseEventHandler<HTMLDivElement> }) => {
	const [animationPhase, setAnimationPhase] = useState<string>('initial')
	const { boardTheme, setBoardTheme } = useContext(BoardThemeContext)
	const winAudio = new Audio(win)
	const loseAudio = new Audio(lose)

	const boardVariants = {
		initial: { opacity: 0, y: 0 },
		enter: { opacity: 1, transition: { duration: .75 } },
	};
	const titleVariants = {
		initial: { opacity: 1 },
		exit: {
			y: [50, -220], // Starts at 0, moves up to -20, then down to 220
			opacity: 0,
			transition: {
				y: {
					type: 'spring',
					stiffness: 300,
					damping: 20,
					duration: 0.25
				},
				opacity: {
					duration: 0.2 // Adjust as needed
				}
			}
		}
	}

	const [difficulty, setDifficulty] = useState<number>(1)
	const [spectating, setSpectating] = useState<boolean>(true)
	const [success, setSuccess] = useState<IStatus>(IStatus.NUETRAL)
	const [count, setCounter] = useState<number>(0)
	const { donutChunks, setDonutChunks } = useContext(DonutChunksContext)		

	const sequencer = (frequency: number, sequence: number[] = [Math.floor(Math.random() * donutChunks.length)]) => {
		// console.log("sequence", sequence)
		if (frequency <= 0)
			throw new Error('Must have a frequence greater than 0')
		const localSequence = [...sequence]
		if (frequency === sequence.length)
			return localSequence
		for (let i = sequence.length; i < frequency; i++)
			localSequence.push(Math.floor(Math.random() * donutChunks.length))
		return localSequence
	}

	const [sequence, setSequence] = useState<number[]>(sequencer(1))
	const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

	const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
		if (!spectating) {
			if (event.currentTarget.id !== sequence[count].toString()) {
				loseAudio.play()
				setSuccess(IStatus.FAIL)
				setSpectating(true)
			} else if (count < sequence.length - 1) {
				setCounter(count + 1)
			} else if (count === sequence.length - 1) {
				winAudio.play()
				setSuccess(IStatus.SUCCESS)
				setSpectating(true)
			}
		}
	}

	useEffect(() => {
		const simulateEventsSequentially = async () => {
			await wait(500)
			setSpectating(true)
			await sequence.reduce(async (promiseChain, id) => {
				await promiseChain
				const element = document.getElementById(id.toString())
				if (element) {
					await wait(800)
					element.dispatchEvent(new MouseEvent('mousedown', { bubbles: true, cancelable: true, view: window }))
					await wait(800)
					element.dispatchEvent(new MouseEvent('mouseup', { bubbles: true, cancelable: true, view: window }))
				}
			}, Promise.resolve())
			await wait(300)
			setSpectating(false)
		}

		simulateEventsSequentially()
	}, [sequence])

	useEffect(() => {
		const returnToTitleScreen = () => {
			const element = document.getElementById('title')
			if (element) {
				element.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: false, view: window }))
			}
		}

		if (success == IStatus.SUCCESS) {
			document.body.style.backgroundColor = '#459731'
			setTimeout(() => {
				document.body.style.backgroundColor = '#2C2929'
				setSuccess(IStatus.NUETRAL)
				setCounter(0)
				setDifficulty(difficulty + 1)
				setSequence(sequencer(difficulty + 1, sequence))
			}, 1000)
		} else if (success == IStatus.FAIL) {
			document.body.style.backgroundColor = '#973131'
			setTimeout(() => {
				document.body.style.backgroundColor = '#2C2929'
				setAnimationPhase('exit')
				setTimeout(() => {
					returnToTitleScreen()
				}, .15 * 1000)
			}, 1000)
		}

	}, [success])

	return (
			<motion.div layout={false} variants={boardVariants} initial='initial' animate='enter'>
				<Grid container alignContent='center' alignItems='center' >
					<Grid item xs={1} sm={2.5} md={3.5} lg={4} xl={5}></Grid>
					<Grid item xs={10} sm={7} md={5} lg={4} xl={2} display='flex' alignContent='center' alignItems='center' justifyContent='center'>
						<Grid container spacing={0} alignContent='center' justifyContent='center'>
							<Grid item textAlign='center' xs={12} style={{ fontSize: '1.75em' }}>
								<h1>{success === IStatus.NUETRAL && !spectating ? 'REPEAT' : ''}
									{success === IStatus.NUETRAL && spectating ? 'WATCH' : ''}
									{success === IStatus.FAIL ? 'NOPE!' : ''}
									{success === IStatus.SUCCESS ? 'CORRECT!' : ''}</h1>

							</Grid>
							<Grid position='relative' item xs={12}>
								<Grid container spacing={0} alignContent='center' justifyContent='center'>
									{
										donutChunks.map((donut) => {
											return (
												<Grid item xs={6} key={`donut-${donut.uid}-${boardTheme}`}>
													<QuarterDonut
														id={donut.id}
														className='quarterdonut'
														innerRadius={innerRadius}
														outerRadius={outerRadius}
														startAngle={donut.startAngle}
														endAngle={donut.endAngle}
														color={(donut.colors as any)[boardTheme]}
														x={donut.x}
														y={donut.y}
														clickHandler={handleClick}
														dimensions={donut.dimensions}
														audio={!spectating ? donut.audio : undefined}
													/>
												</Grid >
											)
										})
									}
								</Grid>
								<div style={{ userSelect: 'none', zIndex: -10000, color: 'white', fontSize: '1em', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
									<h1>Match</h1>
									<h1>{difficulty - 1}</h1>
								</div>
							</Grid>
							<Grid item xs={12}>
								<motion.div exit='exit' variants={titleVariants} initial='initial' animate={animationPhase}>
									<Button type='title' clickHandler={clickHandler}>
										<h1 style={{ fontSize: '2em', zIndex: 1000 }}>SIMON</h1>
									</Button>
								</motion.div>
							</Grid>
						</Grid>

					</Grid>
					<Grid item xs={1} sm={3.5} md={3.5} lg={4} xl={5} ></Grid>
				</Grid>
			</motion.div>
	)
}

export default GameScreen