import audio0 from '../audio/0.wav'
import audio1 from '../audio/1.wav'
import audio2 from '../audio/2.wav'
import audio3 from '../audio/3.wav'
import audio4 from '../audio/4.wav'
import audio5 from '../audio/5.wav'


export const epsilon = 0.1 // Adjust for gap size
export const outerRadius = 100
export const innerRadius = 50

interface DonutColor {
	type: 'classic' | 'pastel' | 'grayscale';
	regular: string;
	touched: string;
}

export interface IDonutChunk {
	uid: number;
	id: number;
	x: number;
	y: number;
	dimensions: {
			width: number;
			height: number;
	};
	startAngle: number;
	endAngle: number;
	audio: string;
	colors: {
			[key: number]: DonutColor;
	};
}

export const EasyDonuts: IDonutChunk[] = [
	{
		uid: 0,
		id: 0,
		x: 0,
		y: 0,
		dimensions: {
			width: outerRadius,
			height: outerRadius * 2,
		},
		startAngle: Math.PI + epsilon, // 270 deg
		endAngle: Math.PI * 2 - epsilon,  // 360 deg
		audio: audio0,
		colors: {
			0: {
				type: 'classic',
				regular: '#73FB52',
				touched: '#C3FFB4',
			},
			1: {
				type: 'pastel',
				regular: '#FFF966',
				touched: '#FFFCB3',
			},
			2: {
				type: 'grayscale',
				regular: '#696969',
				touched: '',
			}
		}
	},
	{
		uid: 1,
		id: 1,
		x: outerRadius,
		y: 0,
		dimensions: {
			width: outerRadius,
			height: outerRadius * 2,
		},
		startAngle: 0 + epsilon, // 270 deg
		endAngle: Math.PI - epsilon,  // 360 deg
		audio: audio1,
		colors: {
			0: {
				type: 'classic',
				regular: '#5277FB',
				touched: '#A2B7FF',
			},
			1: {
				type: 'pastel',
				regular: '#E8887A',
				touched: '#E5B9B3',
			},
			2: {
				type: 'grayscale',
				regular: '#FAFAFA',
				touched: '',
			}
		}
	},
];


export const MediumDonuts: IDonutChunk[] = [
	// top left
	{
		uid: 2,
		id: 0,
		x: 0,
		y: 0,
		dimensions: {
			width: outerRadius,
			height: outerRadius,
		},
		startAngle: 3 * Math.PI / 2 + epsilon, // 270 deg
		endAngle: 2 * Math.PI - epsilon,  // 360 deg
		audio: audio0,
		colors: {
			0: {
				type: 'classic',
				regular: '#73FB52',
				touched: '#C3FFB4',
			},
			1: {
				type: 'pastel',
				regular: '#74E86A',
				touched: '#A8E4A3',
			},
			2: {
				type: 'grayscale',
				regular: '#696969',
				touched: '#A8E4A3',
			}
		}
	},
	// top right
	{
		uid: 3,
		id: 1,
		x: outerRadius,
		y: 0,
		dimensions: {
			width: outerRadius,
			height: outerRadius,
		},
		startAngle: 0 + epsilon, // 0deg
		endAngle: Math.PI / 2 - epsilon, // 90deg
		audio: audio1,
		colors: {
			0: {
				type: 'classic',
				regular: '#FB5252',
				touched: '#FF9A9A',
			},
			1: {
				type: 'pastel',
				regular: '#E8887A',
				touched: '#E5B9B3',
			},
			2: {
				type: 'grayscale',
				regular: '#FAFAFA',
				touched: '',
			}
		}
	},
	// bottom left
	{
		uid: 4,
		id: 2,
		x: 0,
		y: outerRadius,
		dimensions: {
			width: outerRadius,
			height: outerRadius,
		},
		startAngle: Math.PI + epsilon,
		endAngle: 3 * Math.PI / 2 - epsilon,
		audio: audio2,
		colors: {
			0: {
				type: 'classic',
				regular: '#FBE052',
				touched: '#FFF1AA',
			},
			1: {
				type: 'pastel',
				regular: '#FFF966',
				touched: '#FFFCB3',
			},
			2: {
				type: 'grayscale',
				regular: '#B4B4B4',
				touched: '',
			}
		}
	},
	// bottom right
	{
		uid: 5,
		id: 3,
		x: outerRadius,
		y: outerRadius,
		dimensions: {
			width: outerRadius,
			height: outerRadius,
		},
		startAngle: Math.PI / 2 + epsilon,
		endAngle: Math.PI - epsilon,
		audio: audio3,
		colors: {
			0: {
				type: 'classic',
				regular: '#5277FB',
				touched: '#A2B7FF',
			},
			1: {
				type: 'pastel',
				regular: '#6AE8DC',
				touched: '#B5E7E2',
			},
			2: {
				type: 'grayscale',
				regular: '#4A4949',
				touched: '',
			}
		}
	},
];


// export const HardDonuts = [
// 	{
// 		id: 0,
// 		x: 0,
// 		y: 0,
// 		startAngle: Math.PI * 8 / 14 + epsilon, //5.336,
// 		endAngle: Math.PI * 2 - epsilon, // 6.183,
// 		colors: {
// 			regular: '#fff',
// 			touched: '#fff',
// 		}
// 	},
// 	{
// 		id: 1,
// 		x: 0,
// 		y: -30,
// 		startAngle: 0 + epsilon, //5.336,
// 		endAngle: Math.PI / 4 - epsilon, // 6.183,
// 		colors: {
// 			regular: '#000',
// 			touched: '#000',
// 		}
// 	},
// 	{
// 		id: 2,
// 		x: 0,
// 		y: 0,
// 		startAngle: Math.PI * 3 / 2 + epsilon, //5.336,
// 		endAngle: Math.PI * 8 / 14 - epsilon, // 6.183,
// 		colors: {
// 			regular: '#fff',
// 			touched: '#fff',
// 		}
// 	},
// 	{
// 		id: 3,
// 		x: 0,
// 		y: -30,
// 		startAngle: Math.PI / 4 + epsilon, //5.336,
// 		endAngle: Math.PI / 2 - epsilon, // 6.183,
// 		colors: {
// 			regular: '#000',
// 			touched: '#000',
// 		}
// 	},
// 	{
// 		id: 4,
// 		x: 0,
// 		y: 0,
// 		startAngle: Math.PI * 5 / 4 + epsilon, //5.336,
// 		endAngle: Math.PI * 3 / 2 - epsilon, // 6.183,
// 		colors: {
// 			regular: '#fff',
// 			touched: '#fff',
// 		}
// 	},
// 	{
// 		id: 5,
// 		x: 0,
// 		y: -30,
// 		startAngle: Math.PI / 2 + epsilon, //5.336,
// 		endAngle: Math.PI * 3 / 4 - epsilon, // 6.183,
// 		colors: {
// 			regular: '#000',
// 			touched: '#000',
// 		}
// 	},
// 	{
// 		id: 6,
// 		x: 0,
// 		y: -30,
// 		startAngle: Math.PI + epsilon, //5.336,
// 		endAngle: Math.PI * 5 / 4 - epsilon, // 6.183,
// 		colors: {
// 			regular: '#fff',
// 			touched: '#fff',
// 		}
// 	},
// 	{
// 		id: 7,
// 		x: 0,
// 		y: -30,
// 		startAngle: Math.PI * 3 / 4 + epsilon, //5.336,
// 		endAngle: Math.PI - epsilon, // 6.183,
// 		colors: {
// 			regular: '#000',
// 			touched: '#000',
// 		}
// 	},
// ]




export const HardDonuts: IDonutChunk[] = [
	// top left 305.73 deg, 354.26 deg
	{
		uid: 6,
		id: 0,
		x: 0,
		y: -10,
		dimensions: {
			width: outerRadius,
			height: outerRadius / 1.25,
		},
		startAngle: Math.PI * 10 / 6 + epsilon, //5.336,
		endAngle: Math.PI * 2 - epsilon, // 6.183,
		audio: audio0,
		colors: {
			0: {
				type: 'classic',
				regular: '#73FB52',
				touched: '#C3FFB4',
			},
			1: {
				type: 'pastel',
				regular: '#74E86A',
				touched: '#A8E4A3',
			},
			2: {
				type: 'grayscale',
				regular: '#696969',
				touched: '#D4D0D0',
			}
		}
	},
	// top right 0deg 60deg
	{
		uid: 7,
		id: 1,
		x: outerRadius,
		y: -10,
		dimensions: {
			width: outerRadius,
			height: outerRadius / 1.25,
		},
		startAngle: 0 + epsilon,
		endAngle: (Math.PI / 3) - epsilon,
		audio: audio1,
		colors: {
			0: {
				type: 'classic',
				regular: '#FB5252',
				touched: '#FF9A9A',
			},
			1: {
				type: 'pastel',
				regular: '#E8887A',
				touched: '#E5B9B3',
			},
			2: {
				type: 'grayscale',
				regular: '#FAFAFA',
				touched: '#757474;',
			}
		}
	},
	// middle left
	{
		uid: 8,
		id: 2,
		x: 0,
		y: outerRadius - 40,
		dimensions: {
			width: outerRadius,
			height: outerRadius / 1.25,
		},
		startAngle: (Math.PI * 4 / 3) + epsilon,
		endAngle: (Math.PI * 10 / 6) - epsilon,
		audio: audio2,
		colors: {
			0: {
				type: 'classic',
				regular: '#FBE052',
				touched: '#FFF1AA',
			},
			1: {
				type: 'pastel',
				regular: '#FFF966',
				touched: '#FFFCB3',
			},
			2: {
				type: 'grayscale',
				regular: '#A7A7A7',
				touched: '#5C5A5A',
			}
		}
	},
	// middle right 65deg 114.25
	{
		uid: 9,
		id: 3,
		x: outerRadius,
		y: outerRadius - 40,
		dimensions: {
			width: outerRadius,
			height: outerRadius / 1.25,
		},
		startAngle: (Math.PI / 3) + epsilon,
		endAngle: (Math.PI * 8 / 12) - epsilon,
		audio: audio3,
		colors: {
			0: {
				type: 'classic',
				regular: '#5277FB',
				touched: '#A2B7FF',
			},
			1: {
				type: 'pastel',
				regular: '#6AE8DC',
				touched: '#B5E7E2',
			},
			2: {
				type: 'grayscale',
				regular: '#737373',
				touched: '#D1D1D1',
			}
		}
	},
	// bottom left 125deg 174deg
	{
		uid: 10,
		id: 4,
		x: 0,
		y: outerRadius + 30,
		dimensions: {
			width: outerRadius,
			height: outerRadius / 1.25,
		},
		startAngle: (Math.PI * 4 / 3) - epsilon,
		endAngle: Math.PI + epsilon,
		audio: audio4,
		colors: {
			0: {
				type: 'classic',
				regular: '#FFAA09',
				touched: '#FF9A9A',
			},
			1: {
				type: 'pastel',
				regular: '#FFC873',
				touched: '#FFE2B4',
			},
			2: {
				type: 'grayscale',
				regular: '#D6D6D6',
				touched: '#6A6A6A',
			}
		}
	},
	// bottom right 185deg 234 deg
	{
		uid: 11,
		id: 5,
		x: outerRadius,
		y: outerRadius + 30,
		dimensions: {
			width: outerRadius,
			height: outerRadius / 1.25,
		},
		startAngle: (Math.PI * 8 / 12) + epsilon,
		endAngle: Math.PI - epsilon,
		audio: audio5,
		colors: {
			0: {
				type: 'classic',
				regular: '#FA16EF',
				touched: '#fff',
			},
			1: {
				type: 'pastel',
				regular: '#BAB9FF',
				touched: '#D5D5FF',
			},
			2: {
				type: 'grayscale',
				regular: '#4A4949',
				touched: '#E2DFDF',
			}
		}
	},
]
// middle right
// {
// 	id: 2,
// 	x: outerRadius,
// 	y: outerRadius / 12,
// 	startAngle: Math.PI / 3 + epsilon, // 60deg
// 	endAngle: 4 * Math.PI / 6 - epsilon, // 120deg
// 	colors: {
// 		regular: '#FBE052',
// 		touched: '#FFF1AA',
// 	}
// },

// //bottom right
// {
// 	id: 3,
// 	x: outerRadius,
// 	y: outerRadius,
// 	startAngle: 4 * Math.PI / 6 + epsilon, // 120deb
// 	endAngle: Math.PI - epsilon, //  180
// 	colors: {
// 		regular: '#5277FB',
// 		touched: '#A2B7FF',
// 	},
// },
// // middle left
// {
// 	id: 0,
// 	x: 0,
// 	y: 0,
// 	startAngle: 3 * Math.PI / 2 + epsilon,
// 	endAngle: 2 * Math.PI - epsilon,
// 	colors: {
// 		regular: '#73FB52',
// 		touched: '#C3FFB4',
// 	}
// },
// // top left
// {
// 	id: 4,
// 	x: 0,
// 	y: 0,
// 	startAngle: 3 * Math.PI / 2 + epsilon,
// 	endAngle: 2 * Math.PI - epsilon,
// 	colors: {
// 		regular: '#73FB52',
// 		touched: '#C3FFB4',
// 	}
// },
// // top left
// {
// 	id: 5,
// 	x: 0,
// 	y: 0,
// 	startAngle: 3 * Math.PI / 2 + epsilon,
// 	endAngle: 2 * Math.PI - epsilon,
// 	colors: {
// 		regular: '#73FB52',
// 		touched: '#C3FFB4',
// 	}
// },
// ]