interface IButtonProps {
	children?: React.ReactElement | string | number
	type: string
	clickHandler: React.MouseEventHandler<HTMLDivElement> 
}
const Button = ({ children, type, clickHandler }: IButtonProps)=> {
	return (
			<div className={`button ${type}`} id={type} onClick={clickHandler}>
					<div className="text-wrapper">{children}</div>
			</div>
	);
};

export default Button