import * as React from 'react'
import * as d3 from 'd3';


interface StatefulColor {
  regular: string,
  touched: string,
  type: string,
}

interface QuarterDonutProps {
  id: number;
  x: number;
  y: number;
  dimensions: Record<string, number>
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  color: StatefulColor;
  className: string;
  width?: number;
  height?: number;
  clickHandler?: React.MouseEventHandler<HTMLDivElement>;
  audio?: string;
}

const QuarterDonut: React.FC<QuarterDonutProps> = ({ id, x, y, dimensions, innerRadius, outerRadius, startAngle, endAngle, color, audio, clickHandler }) => {
  const [statefulColor, setStatefulColor] = React.useState(color.regular);

  const audioPlay = new Audio(audio)

  const clickHandlerWithAudio: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const localEvent = { ...event }

    if (audioPlay)
      audioPlay.play().catch(e => console.error("Error playing audio:", audio));
    if (clickHandler)
      clickHandler(localEvent)
  }

  const handlePressDown = () => {
    setStatefulColor(color.touched); // Change to the clicked color on press down
  };

  const handlePressUp = () => {
    setStatefulColor(color.regular); // Revert to the regular color on press up
  };

  const arc = d3.arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle)
    .cornerRadius(5);

  // Define a dummy object to satisfy TypeScript requirements
  const dummyArcObject = {
    innerRadius: innerRadius,
    outerRadius: outerRadius,
    startAngle: startAngle,
    endAngle: endAngle,
    padAngle: 0,
    padRadius: innerRadius,
    cornerRadius: 0
  };

  const pathData = arc(dummyArcObject) as string;

  return (
    <div onMouseDown={handlePressDown}
      onMouseUp={handlePressUp}
      onTouchStart={handlePressDown} // For touch interaction
      onTouchEnd={handlePressUp}
      id={id.toString()}
      onClick={clickHandlerWithAudio}
    >

      <svg
        viewBox={`${x} ${y} ${dimensions.width} ${dimensions.height}`}
      >
        <path d={pathData} fill={statefulColor} transform={`translate(${outerRadius}, ${outerRadius})`} />
      </svg>
    </div>

  );
};


export default QuarterDonut;