import { Grid } from '@mui/material'
import QuarterDonut from '../components/DonutChunk'
import { EasyDonuts, MediumDonuts, HardDonuts, innerRadius, outerRadius } from '../consts'
import Button from '../components/Button'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useEffect, useState } from 'react'
import DifficultyContext from '../context/DifficultyContext'
import BoardThemeContext from '../context/BoardThemeContext'
import DonutChunksContext from '../context/DonutChunksContext'

const TitleScreen = ({ clickHandler }: { clickHandler: React.MouseEventHandler<HTMLDivElement> }) => {
	const [animationPhase, setAnimationPhase] = useState<string>('enter')
	const { boardDifficulty, setBoardDifficulty } = useContext(DifficultyContext)
	const { boardTheme, setBoardTheme } = useContext(BoardThemeContext)
	const { donutChunks, setDonutChunks } = useContext(DonutChunksContext)

	const variants = {
		initial: { opacity: 1, y: 0 },
		exit: {
			y: [-50, 220], // Starts at 0, moves up to -20, then down to 220
			opacity: 0,
			transition: {
				y: {
					type: 'spring',
					stiffness: 300,
					damping: 20,
					duration: .25
				},
				opacity: {
					duration: 0.2 // Adjust as needed
				}
			}
		}
	};

	const onClickWithAnimation: React.MouseEventHandler<HTMLDivElement> = (e) => {
		const localEvent = { ...e }
		setAnimationPhase('exit')
		setTimeout(() => {
			clickHandler(localEvent)
		}, .15 * 1000)
	}	

	useEffect(() => {
		console.log(boardTheme)
	}, [boardTheme])

	useEffect(() => {
		switch(boardDifficulty) {
			case 0:
				setDonutChunks(EasyDonuts)
				break
			case 1:
				setDonutChunks(MediumDonuts)
				break
			case 2:
				setDonutChunks(HardDonuts)
				break
			default:
				setDonutChunks(MediumDonuts)
		}
	}, [boardDifficulty])


	return (
		<Grid container alignContent='center' alignItems='center' marginTop={(window.innerHeight * .02)}>
			<Grid item xs={4} md={4.5} lg={4.75}></Grid>
			<Grid item xs={4} md={3} lg={2.5} display='flex' alignContent='center' alignItems='center' justifyContent='center'>
				<Grid container direction='row'>
					{
						donutChunks.map((donut) => {
							return (
									<Grid item xs={6} key={`donut-${donut.uid}-${boardTheme}`}>
										<QuarterDonut
											id={parseInt(`${donut.id}0`)}
											className={`quarterdonut ${boardTheme}`}
											innerRadius={innerRadius}
											outerRadius={outerRadius}
											startAngle={donut.startAngle}
											endAngle={donut.endAngle}
											color={(donut.colors as any)[boardTheme]}
											x={donut.x}
											y={donut.y}
											dimensions={donut.dimensions}
											audio={donut.audio}
										/>
									</Grid >
							)
						})
					}
					<Grid item xs={12} textAlign='center' paddingBottom='45px'>
						<AnimatePresence>
							<motion.div variants={variants} initial="initial" animate={animationPhase} exit="exit">
								<h1 style={{ fontSize: '2em' }}>SIMON</h1>
							</motion.div>
						</AnimatePresence>
					</Grid>
					<Grid item xs={12} style={{ paddingBottom: '40px' }}>
						<Button type='game' clickHandler={onClickWithAnimation}>
							New Game
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button type='settings' clickHandler={clickHandler}>
							Settings
						</Button>
					</Grid>
				</Grid >
			</Grid>
			<Grid item xs={2}></Grid>
		</Grid>
	)
}

export default TitleScreen